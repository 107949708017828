.wrapper {
  margin: 0 auto;
}

.header {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--color-text);
}

.rowWrapper {
  overflow: hidden;
  @media screen and (min-width: 768px) {
    margin-top: 50px;
    .header {
      text-align: center;
      flex: 1;
      padding: 15px;
      border-top: 1px solid var(--color-border-dark);
      border-bottom: 1px solid var(--color-border-dark);
      color: var(--color-text);
      font-size: 22px;
    }
  }
}

// landing pages
$text-color: var(--color-text);

.greetings {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: $text-color;
  position: relative;
  display: inline-block;
}

.articleListWrapper {
  margin: 20px 0;
  margin: 20px 0 60px 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px 15px;

  .article {
    height: 240px;
  }

  @media screen and (min-width: 1280px) {
    grid-gap: 40px 90px;
  }
}
