@mixin placeholderTag($height, $borderTopLeftRadius, $borderBottomRightRadius) {
  background-color: #252450;
  color: var(--color-white);
  width: 80px;
  border-top-left-radius: $borderTopLeftRadius;
  border-bottom-right-radius: $borderBottomRightRadius;
  position: absolute;
  top: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  height: $height;
}
