@import "@styles/buttonmixins";

$button-color: var(--color-secondary);

.card {
  border-radius: 10px;
  background: linear-gradient(90deg, #07D170 0%, #1F82BF 100%);
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 226, 213, 0.1);

  @media screen and (min-width: 768px) {
    border-radius: 0;
    padding: 20px;
  }
  @media screen and (min-width: 1024px) {
    padding-left: 60px;
  }
  @media screen and (min-width: 1280px) {
    padding-left: 75px;
  }
  @media screen and (min-width: 1440px) {
    padding-left: 85px;
  }
}

.podercardContent {
  z-index: 1;
  position: relative;
  span {
    font-size: 12px;
    line-height: 14px;
    color: var(--color-white);
  }
  h3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    color: var(--color-white);
  }
  .button {
    @include button-theme-light($button-color);
    border: 2px solid var(--color-white) !important;
    min-width: 125px;
  }
  @media screen and (max-width: 540px) {
    h3 {
      width: 70%;
    }
  }
  @media screen and (max-width: 425px) {
    h3 {
      width: 50%;
    }
  }
  @media screen and (max-width: 375px) {
    h3 {
      width: 60%;
    }
  }
  @media screen and (max-width: 320px) {
    h3 {
      width: 100%;
    }
  }

  @media screen and (min-width: 768px) {
    h3 {
      font-size: 30px;
      width: 50%;
      line-height: 1.2;
    }

    .button {
      margin-top: 10px;
    }
  }

  @media screen and (min-width: 1280px) {
    h3 {
      font-size: 40px;
      width: 30%;
    }
  }
}

.poderCardImage {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  place-items: center;

  img {
    height: 120px;
    width: 170px;
  }
  @media screen and (max-width: 375px) {
    right: 0;
    top: 15%;
    transform: translateY(0%);
  }
  @media screen and (max-width: 320px) {
    display: none;
  }

  @media screen and (min-width: 768px) {
    right: 5%;

    img {
      height: 180px;
      width: 230px;
    }
  }
  @media screen and (min-width: 1024px) {
    right: 7.9%;
  }
  @media screen and (min-width: 1440px) {
    right: 7%; 
  }
  @media screen and (min-width: 2000px) {
    right: 5%;
  }
}
