.content {
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: var(--color-text-dark);
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-text);
    .link {
      color: var(--color-secondary);
    }
  }
}
