@import "@styles/buttonmixins";
@import "@styles/placeholdermixins";
$button-color: var(--color-secondary);
$white-color: var(--color-white);

.articleFeed {
  position: relative;
  .carousel {
    scroll-behavior: smooth;
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    padding-right: 10px;
    @media (max-width: 768px) {
      margin-left: auto;
      margin-right: -5px;
      width: 105%;
    }
  }
  .slideLeft {
    width: 40px;
    height: 47px;
    background-color: rgba(255, 255, 255, 0.9);
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M6 1.01224L1 6.00612L6 11' stroke='%23007E84' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: center;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: none;
    position: absolute;
    top: 50%;
    transform: translateY(-100%);
    @media (max-width: 768px) {
      left: -20px;
    }
  }
  .slideRight {
    width: 40px;
    height: 47px;
    background-color: rgba(255, 255, 255, 0.9);
    background-image: url("data:image/svg+xml,%3Csvg width='7' height='12' viewBox='0 0 7 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M0.999999 10.9878L6 5.99388L1 1' stroke='%23007E84' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E ");
    background-repeat: no-repeat;
    background-position: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: none;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-100%);
    @media (max-width: 768px) {
      right: -20px;
    }
  }
}

.articleFeedDesktop {
  display: grid;
  grid-template-areas:
    "second first third"
    "fourth first fifth";
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: 1fr 1.1fr 1fr;
  grid-gap: 30px 40px;

  .article-1 {
    grid-area: first;
  }

  .article-2 {
    grid-area: second;
  }

  .article-3 {
    grid-area: third;
  }

  .article-4 {
    grid-area: fourth;
  }

  .article-5 {
    grid-area: fifth;
  }

  @media screen and (min-width: 1240px) {
     column-gap: 100px;
  }
}

.articleFeedDesktopWithSearch {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(3, 1fr);
}

.searchBar {
  input {
    background-color: var(--color-gray);
    border: 1px solid var(--color-white);
    box-sizing: border-box;
    border-radius: 20px;
    color: var(--color-secondary);
    padding: 12px 24px;
    max-height: 40px;
    &::placeholder {
      color: inherit;
    }
    &:-ms-input-placeholder {
      color: inherit;
    }
    &::-ms-input-placeholder {
      color: inherit;
    }
    &:focus {
      box-shadow: none;
      border: 1px solid var(--color-secondary);
    }
  }
}

.filterBar {
  overflow-x: auto;
  scroll-behavior: smooth;
  button, a {
    @include button-hover-theme($button-color, light);
    text-transform: capitalize;
    display: inline-block;
    white-space: nowrap;
    min-width: 120px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    box-shadow: none;
    text-decoration: none;
    &:focus, &:hover {
        .iconButton{
        stroke: white;
      }
    }
  }

  .selected {
    @include button-theme-dark($button-color);
    opacity: 1;

    .iconButton {
      stroke: white !important;
    }
  }

  .unselected {
    // Touch Screen Buttons
    @media (hover: none) and (pointer: coarse) {
      background-color: $white-color !important;
      color: $button-color !important;

      .iconButton {
        stroke: inherit !important;
      }
    }
  }

  @media screen and (min-width: 411px) {
    button {
      font-size: 14px;
    }
  }
}

.pagination {
  .previous, .next {
    a {
      background-color: transparent;
      height: 40px;
      background-repeat: no-repeat;
      background-position: center;
      border: none;
      span {
        display: none;
      }
    }
  }
  .previous {
    a {
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M8 1L1 8L8 15' stroke='%235C7AFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E");
    }
  }
  .next {
    a {
      background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M1 15L8 8L1 1' stroke='%235C7AFF' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E");
    }
  }
  .paginationItems {
    a, span {
      font-size: 16px;
      line-height: 13px;
      color: var(--color-secondary) !important;
      background-color: transparent !important;
      border: none;
    }
    &.active {
      span {
        background-color: transparent;
        border: none;
        color: var(--color-text);
        text-decoration: underline;
      }
    }
  }
  .ellipsis {
    a, span {
      font-size: 16px;
      line-height: 13px;
      color: var(--color-secondary);
      background-color: transparent;
      border: none;
      padding-left: 5px;
      padding-right: 5px;
    }
  }
}

.articleListLink {
  text-decoration: none !important;
}

.articleList {
  border-bottom: 2px solid var(--color-gray);
  padding: 20px;
  .info {
    width: 55%;
    h4 {
      font-size: 16px;
      line-height: 19px;
      color: var(--color-text);
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    p {
      font-size: 10px;
      line-height: 12px;
      color: var(--color-text);
      span {
        color: var(--color-primary-dark);
      }
    }
    .button {
      width: 100px;
      height: 22px;
      @include button-theme-dark(var(--color-primary-dark));
      font-size: 12px;
      line-height: 14px;
      padding: 0;
      border-radius: 70px;
      box-shadow: 0 4px 10px 1px rgb(157 156 181 / 30%);
      display: -webkit-box;
      -webkit-box-pack: center;
      -webkit-box-align: center;
    }
  }
  .imageWrapper {
    width: 40%;
    height: 80px;
    position: relative;

    .articleImage {
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }

    .lock {
      @include placeholderTag(70px, 10px, 10px);
    }

    .premiumUserAndArticle {
      @include placeholderTag(30px, 10px, 10px);
    }
  }
}

