@import "@styles/placeholdermixins";

.leftColumn {
  flex: 0.5;
  margin-right: 30px;

  span {
    text-transform: capitalize;
    color: var(--color-primary-dark);
    font-size: 14px;
  }

  small {
    display: block;
    color: var(--color-text);
    font-size: 12px;
  }

  p {
    color: var(--color-text);
    font-size: 20px;
    margin: 10px 0 0 0 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
            line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .articleDescription {
    font-size: 15px;
    -webkit-line-clamp: 4;
            line-clamp: 4;
  }

  .headerDivider {
    height: 3px;
    width: 100px;
    border-radius: 8px;
    background-color: var(--color-primary-dark);
    margin-top: 10px !important;
  }
}

.imageWrapper {
  position: relative;
  flex: 0.5;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3) !important;

  img {
    height: 100% !important;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .lock {
    @include placeholderTag(70px, 20px, 20px);
  }

  .premiumUserAndArticle {
    @include placeholderTag(40px, 20px, 20px);
  }
}
