@import '@styles/buttonmixins';
@import "@styles/modalmixins";
@import "@styles/placeholdermixins";
$text-color: var(--color-text);
$button-color: var(--color-primary-dark);
$backdrop-color: var(--color-modal-backdrop);

.link {
  min-height: 230px;
  flex: 1;
  width: inherit;
  text-decoration: none !important;

  &.inactiveLink {
    pointer-events: none;
  }
}

.card {
  min-height: 230px;
  flex: 1;
  width: inherit;
  .backgroundImage {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    height: 130px;
    object-fit: cover;
  }
  :hover {
    cursor: pointer;
  }
  .cardBody {
    padding: 10px 13px;
    .content {
      background: rgba(240, 243, 251, 0.8);
      border-radius: 10px;
      padding: 8px;
      p.info {
        font-size: 10px;
        line-height: 12px;
        color: $text-color;
        span.category {
          color: var(--color-primary-dark);
        }
      }
      h4 {
        font-weight: 510;
        font-size: 14px;
        line-height: 17px;
        height: 34px;
        color: $text-color;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }

      .button {
        font-size: 12px;
        line-height: 14px;
        margin-top: 8px;
        min-width: 96px;
        height: 22px;
        padding: 0;
        display: -webkit-box;
        -webkit-box-pack: center;
        -webkit-box-align: center;
        background-color: $button-color;
        border: 1px solid $button-color;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .lock {
    @include placeholderTag(70px, inherit, inherit);
  }

  .premiumUserAndArticle {
    @include placeholderTag(40px, inherit, inherit);
  }
}

.lockedMessage {
  text-align: center;
  border-radius: 20px;
  :global(.modal-header) {
    border-bottom: none;
  }
  :global(.modal-content) {
    border-radius: 20px
  }
  :global(.modal-body) {
    padding-top: 0;
    h3 {
      font-weight: 500;
      font-size: 30px;
      line-height: 36px;
    }
    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      a {
        color: var(--color-secondary);
      }

      a:hover {
        color: var(--color-primary);
      }
    }
  }
}

.modalBackdrop {
  @include modal-backdrop($backdrop-color);
}
