// @import "@styles/buttonmixins";
$button-color: var(--color-secondary);

.modalContent {
  min-height: 60vh;
  justify-content: center;
  .modalBody {
    background-image: url('https://dd8u8m75fccea.cloudfront.net/v3/assets/blt224b6cd2a9d5dbf3/blt38b874de2cfd64db/623b9b4dfac4b208fa1884bd/registrationBackground.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50% 10%;
    flex: initial;

    @media screen and (min-width: 768px) {
      background-size: cover;
      background-position: top right;
      padding: 10% !important;
    }
  }
}

.content {
  h2 {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: var(--color-text-dark);
  }
  p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: var(--color-text);
    .link {
      color: var(--color-secondary);
    }
  }
}

.handles {
  button {
    display: block;
    width: 250px;
    min-height: 42px;
    font-size: 14px;
    line-height: 17px;
    margin: 0 auto;
    &.light {
      // @include button-hover-theme($button-color, light);
    }
    &.dark {
      // @include button-hover-theme($button-color, dark);
    }
  }
}
