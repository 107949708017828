@import "@styles/buttonmixins";

$button-color: var(--color-secondary);
$button-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);

// Largely pilfered from button.module.scss
.link {
  @include button-theme-dark($button-color);
  padding: 5px 12px;
  border-radius: 70px;
  outline: none;
  box-shadow: $button-shadow;
  &:focus {
    box-shadow: $button-shadow;
  }
  &:hover, &:active {
    text-decoration: none !important;
  }
}
