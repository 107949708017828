@mixin navigate-button-active {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent !important;
  border: none;
  padding: 1rem 0.75rem;
  box-shadow: none;
  border-radius: 0;
}

@mixin navigate-button-inactive($top-radius, $bottom-radius) {
  border: 1px solid #dee2e6;
  border-radius: 0;
  #{$top-radius}: 0.25rem;
  #{$bottom-radius}: 0.25rem;
  box-shadow: none;
  pointer-events: none;
  height: 38px;
  width: 30px;
  color: #6c757d;
  background-color: #fff;
  border-color: #dee2e6;
}

.pagination {
  a {
    text-decoration: none;
    box-shadow: none !important;
  }
  .previous {
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M8 1L1 8L8 15' stroke='%23007E84' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E");
    @include navigate-button-active();
  }
  .previousDisabled {
    @include navigate-button-inactive(border-top-left-radius, border-bottom-left-radius)
  }
  .next {
    background-image: url("data:image/svg+xml,%3Csvg width='9' height='16' viewBox='0 0 9 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M1 15L8 8L1 1' stroke='%23007E84' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E %3C/svg%3E");
    @include navigate-button-active();
  }
  .nextDisabled {
    @include navigate-button-inactive(border-top-right-radius, border-bottom-right-radius)
  }
  .paginationItems {
    font-size: 16px;
    line-height: 13px;
    color: var(--color-secondary) !important;
    background-color: transparent !important;
    border: none;
    box-shadow: none;
    border-radius: 0;
    &.active {
      background-color: transparent !important;
      border: none;
      color: var(--color-text);
      text-decoration: underline;
      pointer-events: none;
    }
  }
  .ellipsis {
    a, span {
      font-size: 16px;
      line-height: 11px;
      color: var(--color-secondary);
      background-color: transparent;
      border: none;
    }
  }
}
