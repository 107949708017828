@import "@styles/buttonmixins";
$button-color: var(--color-secondary);
$text-color: var(--color-white);

@mixin articleText () {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.8px;
  color: var(--color-text);
}

.articleText {
  padding: 0 15px;
  @include articleText();
  p {
    @include articleText();
  }
}

.articleRichText {
  padding: 0 15px;
  @include articleText();
  a {
    color: var(--color-secondary) !important;
  }
  *:not(p) {
    a {
      color: inherit;
    }
  }
  p {
    @include articleText();
  }
  h1, h2, h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: var(--color-primary-dark);
  }
  h1 {
    font-size: 24px;
  }
  ol {
    margin: 10px 0 30px 0;
    max-width: 95%;
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 15px;
      color: var(--color-text);
    }
  }
  ul {
    margin: 10px 0 30px 0;
    list-style: none;
    max-width: 95%;
    padding: 0 16px;
    li {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      margin-bottom: 15px;
      color: var(--color-text);
      position: relative;
      padding-left: 15px;
      &:before{
        content:"•";
        font-size: 13px;
        position: absolute;
        width: auto;
        left: -5px;
      }
    }
  }
}

.articleCta {
  @include button-theme-dark($button-color);
  font-size: 18px;
  width: 100%;
  max-width: 320px;
  padding-top: 12px;
  padding-bottom: 12px;
  * {
    color: inherit !important;
    font-size: inherit !important;
  }
}

.articleImage {
  object-fit: fill;
  height: auto;
  width: 100%;
}

.articleYoutubeVideo {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 15px 50% 15px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}


.blockQuote, .imageWrapper, .adUnit {
  padding: 0 15px;
}

.adUnit {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.bannerAd {
  width: 95%;
  padding: 1rem;
  margin: 15px auto;
  overflow: hidden;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 10px;

  h3, p {
    color: white;
    width: 75%;
  }

  .linkButton {
    background-color: white;
    color: var(--color-secondary);
    border: 0;
  }

  @media screen and (min-width: 425px) {
    h3, p {
      width: 60%;
    }
  }

  @media screen and (min-width: 768px) {
    width: 100%;
    height: 250px;
    padding: 2rem 3rem;
    margin: 15px 0;
    border-radius: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    h3, p {
      width: 100%;
    }

    .linkButton {
      padding: 7px 3rem;
    }
  }
}

.halfPageBanner {
  padding: 60px;
  margin-left: 15px;
  margin-right: 15px;
  background-color: var(--color-gray);
  border-radius: 15px;

  h2 {
    font-size: 40px;
    font-weight: 700;
  }

  .bulletPoints {
    padding-left: 15px;

    & > div {
      &:not(:first-child) {
        margin-top: 20px;
      }

      img {
        width: 40px;
        height: 40px;
      }

      P {
        margin: 0 0 0 15px;
        font-weight: 400;
        font-size: 20px;
      }
    }
  }

  .ctaButton {
    padding: 8px 45px;
    box-shadow: 0px 5.75653px 14.3913px 1.43913px rgba(157, 156, 181, 0.3);
  }

  @media screen and (max-width: 768px) {
    padding: 40px;
  }

  @media screen and (max-width: 425px) {
    padding: 30px;

    h2 {
      font-size: 28px;
    }

    .bulletPoints {
      padding-left: 0;

      & > div {
        p {
          font-size: 16px;
        }
      }
    }
  }
}

.testimonialCta {
  margin-left: 15px;
  margin-right: 15px;
  background-image: linear-gradient(to right, rgba(92, 122, 255, 0.2), rgba(3, 200, 176, 0.2));
  border-radius: 15px;
  padding: 14px;
  box-shadow: 0px 4.82189px 16.8766px 1.20547px rgba(0, 0, 0, 0.15);

  .innerWrapper {
    background-color: white;
    border-radius: 6px;
    padding: 18px 70px;

    @media screen and (max-width: 425px) {
      padding: 18px 35px;
    }

    .thumbnail {
      position: relative;

      img {
        position: relative;
        object-fit: cover;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        z-index: 2;
      }

      .thumbnailBorder {
        position: absolute;
        bottom: -2px;
        right: -3px;
        height: 80px;
        width: 80px;
        border-radius: 50%;
        background-color: var(--color-primary);
        z-index: 1;
      }
    }

    .personalDetails {
      color: var(--color-text-dark-blue);

      p {
        margin-bottom: 0;
        font-weight: 700;
      }

      span {
        font-style: italic;
      }
    }

    .horizontalLine {
      width: 120px;
      height: 3px;
      display: block;
      background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary));
      border-radius: 3px;
    }

    .quote {
      position: relative;
      color: var(--color-text-dark-blue);

      & > div {
        position: relative;
        top: 15px;
        left: -25px;
        display: inline-block;
        transform: scale(8);
        color: var(--color-primary);

        &:nth-child(2) {
          top: initial;
          left: initial;
          bottom: -40px;
          right: -25px;
        }
      }

      @media screen and (max-width: 425px) {
        & > div {
          transform: scale(5.5);
          top: 10px;
          left: -17px;

          &:nth-child(2) {
            bottom: -30px;
            right: -15px
          }
        }
      }
    }

    .subHeader {
      color: var(--color-text-dark-blue);
      font-weight: 600;
      font-size: 18px;
    }
  }
}
