$main-color: var(--color-secondary);

.description {
  font-size: 13px;
  margin-bottom: 4px;
}

.bullet {
  font-size: 12px;
  color: var(--color-text);
  padding-left: 1rem;
}

// This is copied from the CheckboxGroup.  Admittedly not DRY.
.check {
  input {
    border: 3px solid $main-color;
    filter: drop-shadow(0px 4px 4px rgba(8, 206, 115, 0.2));
    border-radius: 50% !important;
    //if needed to remove check mark
    &:checked/*[type=checkbox*/ {
      background-color: $main-color;
      border: 3px solid $main-color;
      background-image: none;
    }
  }
}

.label {
  font-size: 14px;
  line-height: 17px;
  color: var(--color-text);
}
