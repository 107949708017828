.label {
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  color: var(--color-text-dark);
}

.submitButton {
  display: block;
  width: 250px;
  min-height: 42px;
  font-size: 14px;
  line-height: 17px;
  margin: 0 auto;
}

.resendLink {
  line-height: 17px;
  margin-left: 2px;
  color: var(--color-secondary);
}

.confirmationContent {
  margin-top: 35px;
}

.mobilePhoneInputContent {
  margin-top: 40px;
}
