@import "@styles/placeholdermixins";

.link {
  text-decoration: none !important;
  color: black;

  &.inactiveLink {
    pointer-events: none !important;
  }

  .cardImageWrapper {
    position: relative;

    .cardImage {
      width: 100% !important;
      height: 200px;
      border-radius: 20px;
      object-fit: cover;
      object-position: center;
      box-shadow: 0px 4px 10px 1px rgba(157, 156, 181, 0.3);
    }

    .fullCardImage {
      height: 520px;
    }

    .lock {
      @include placeholderTag(70px, 20px, 20px);
    }

    .premiumUserAndArticle {
      @include placeholderTag(40px, 20px, 20px);
    }
  }

  .cardHeader {
    span {
      text-transform: capitalize;
      color: var(--color-primary-dark);
      font-size: 14px;
    }

    small {
      display: block;
      color: var(--color-text);
      font-size: 12px;
    }

    p {
      color: var(--color-text);
      font-size: 20px;
      margin: 10px 0 0 0 !important;
    }

    .headerDivider {
      height: 3px;
      width: 100px;
      border-radius: 8px;
      background-color: var(--color-primary-dark);
      margin-top: 10px !important;
    }

    .articleDescription {
      font-size: 15px;
    }
  }

  .fullCardHeader {
    span, small, p, .headerDivider {
      display: block;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
}
